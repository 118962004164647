import React,{useEffect} from 'react'

function Platform() {

  useEffect(()=>{
    // Navigate to another URL after the component mounts
    window.location.href = 'https://dashboard.gmscope.org';
  },[])
  return (
    <div></div>
  )
}

export default Platform