import React from 'react'
import FNABanner from '../components/FNABanner'
import PropItem from '../components/PropItem'

const FnaAdapterPage= () => {

  const fnaProperties =[
    {
      src:"./images/fna/invasive.png",
      title:"Minimally Invasive",
      value:"Pencil grip,designed to harvest more cellular material"
    },
    {
      src:"./images/fna/vector.png",
      title:"Increased Cellular Yield",
      value: "Versus traditional FNA (fine needle aspiration) technique"
    },
    {
      src:"./images/fna/lock.png",
      title:"Lightweight and reusable",
      value: "Designed for easy, one-handed use, providing optimal negative pressure"
    },
    {
      src:"./images/fna/heart.png",
      title:"Reduce non-diagnositic rate vs traditional FNA technique",
      value:"Reduced ssampling time with fewer passes"
    }
  ]

  const fna_organ_procedures =[
    {
      src:"./images/fna/breast.png",
      title:"Breast Nodules",
    },
    {
      src:"./images/fna/cyringe.png",
      title:"Breast Cyst Fluids",
    },
    {
      src:"./images/fna/Vectorlymph.png",
      title:"Lymph Nodes",
    },
    {
      src:"./images/fna/salivery.png",
      title:"Salivary Gland",
    },
    {
      src:"./images/fna/Vectorthyroid.png",
      title:"Thyroid Nodules",
    },
    {
      src:"./images/fna/thyroid_cysts.png",
      title:"Thyroid Cysts",
    }
  ]

  return (
    <div className="pb-5">
      <FNABanner />

      <br />
      {/* FNA Properties Section */}
      <section className="container mx-auto pt-5 mb-5">
        <div  className='card-grid my-5'>
          {
              fnaProperties.map(data => (
                <PropItem src={data.src} title={data.title} value={data.value} key={data.title} />
              ))
          }           
        </div>
      </section>
      <br />
      <br />
      <br />

      {/* FNA Procedure Section */}
      <section className="bg-gray-100 p-5">

        <div className="container mx-auto">
          <br />
          <br />
            <h1 className="text-center text-2xl font-bold "> FNA Procedures In The Following Organs</h1>

            <div className='fna_procedures'>
              {
                fna_organ_procedures.map(data => (
              <div key={data.title} className="px-3">
                <div  className='bg-white rounded-lg p-3 shadow-lg flex flex-row items-center justify-contents-center'>
                    <img src={data.src} style={{height: 70,width:75}} alt={"Hell0"} className="bg-blue-100 mr-3 rounded-md p-3"/>
                    <span className="font-bold">{data.title}</span>
                </div>
            </div>   

                ))
              }
           
            </div>


        </div>
        <br />
        <br />

      </section>

      <section className="lg:p-10 p-2 py-10">
        <h1 className="text-center text-2xl mb-5 font-bold"> Properties </h1>

        <p className='text-center lg:w-1/2 mx-auto'> 
          <div className="lg:inline-block" style={{marginRight:15,marginBottom:12}}><div style={{display:"flex",height:16,alignItems:"center"}}><img src="/tick.png" style={{marginRight:5}} alt="tick"/>Reusable</div></div>
          <div className="lg:inline-block" style={{marginRight:15,marginBottom:12}}><div style={{display:"flex",height:16,alignItems:"center"}}><img src="/tick.png" style={{marginRight:5}} alt="tick"/>Light Weight and Portable</div></div> 
          <div className="lg:inline-block" style={{marginRight:15,marginBottom:12}}><div style={{display:"flex",height:16,alignItems:"center"}}><img src="/tick.png" style={{marginRight:5}} alt="tick"/>Better Aspirate Yield</div></div>  
          <div className="lg:inline-block" style={{marginRight:15,marginBottom:12}}><div style={{display:"flex",height:16,alignItems:"center"}}><img src="/tick.png" style={{marginRight:5}} alt="tick"/>Adapted To Use With 15CC Syringe Design</div></div>  
          <div className="lg:inline-block" style={{marginRight:15,marginBottom:12}}><div style={{display:"flex",height:16,alignItems:"center"}}><img src="/tick.png" style={{marginRight:5}} alt="tick"/>Easy Usage By Less Skilled Staff</div></div>  
          <div className="lg:inline-block" style={{marginRight:15,marginBottom:12}}><div style={{display:"flex",height:16,alignItems:"center"}}><img src="/tick.png" style={{marginRight:5}} alt="tick"/>Minimal Training Require For Usage</div></div>  
          <div className="lg:inline-block" style={{marginRight:15,marginBottom:12}}><div style={{display:"flex",height:16,alignItems:"center"}}><img src="/tick.png" style={{marginRight:5}} alt="tick"/>Enables Faster FNA Procedures</div></div> 
 
          
          
          
          
          
          
        </p>
      </section>

      <section className="bg-gray-100 py-10">
      <h1 className="text-center text-2xl font-bold mb-10 "> How To Adapt A Syringe Into The Device</h1>
        <div className="flex mx-auto lg:w-3/4 px-2" style={{display:"flex",justifyContent:"center",alignItems:"center"}} >

              <div className='bg-white p-3 rounded-md' style={{}}>
                <div className='bg-gray-50 rounded-md'><img src="./images/fna/part1.png" style={{height:140, minWidth:100}} className='mx-auto p-3 mb-3' alt="fna" /></div>
                <p className="text-xs lg:text-center">First you need to ensure the device is in a locked position</p>
              </div>

              <img src="./images/fna/separator.png" className="my-auto" alt="Spacer" style={{ width:100,minWidth:50}}/>

              <div className='bg-white p-3 rounded-md lg:flex lg:w-3/4' style={{alignItems:"center"}}>
                <div className='bg-gray-50 rounded-md mr-4' style={{minWidth:100}}><img src="./images/fna/part2.png" style={{height:140,width:130}} className='mx-auto p-3 mb-3 ' alt="fna" /></div>
                <p className="text-xs lg:w-3/4 "  style={{}}>Now fit in your syringe, sending the needle cap then the front part of the barrel through the inner opening, then adjusting the rest of the body to fit into the adapter. Note that the flange of the syringe should be well fitted into the flange holders of the adapter, and the posterior blade of the plunger should be inserted in the groove of the adapter’s cap.</p>
              </div>
        
        </div>
      </section>

      <section className='container mx-auto py-10'>

        <h1 className="text-center text-2xl font-bold mb-10 "> How To Use For FNA Procedures</h1>

        {/* Step 1 */}
        <div  className="flex mx-auto px-2 mb-5" style={{display:"flex"}} >

          <div className='mr-3'>
            <img src="./images/fna/step_1.png" alt="step 1" className="" style={{width: 100}} /> 
          </div>

          <div className='w-3/4 my-auto '>
            <p>
              Carefully remove the needle cap from the needle.
            </p>
          </div>

        </div>



        {/* Step 2 */}
        <div  className="flex mx-auto px-2 mb-5" style={{display:"flex"}} >

          <div className='mr-3'>
            <img src="./images/fna/step_2_image.png" alt="step 2" className="" style={{width: 100}} /> 
          </div>

          <div className='w-3/4  my-auto '>
            <p>
            After haven immobilized your noddle or lesion with your less dominant hand, use your dominant hand to insert the needle into the lesion.
            </p>
          </div>

        </div>


        {/* Step 3 */}
        <div  className="flex mx-auto  px-2  mb-5" style={{display:"flex"}} >

          <div className='mr-3'style={{width: 100,height: 100,backgroundImage:"url('./images/fna/bg.png')",backgroundSize:"contain",position:"relative",backgroundRepeat:"no-repeat"}}>
            <img src="./images/fna/step_3_image.png" alt="step 1" className="mx-auto pt-3" style={{width: 60, }} /> 
            <img src="./images/fna/3.png" alt="" style={{position:"absolute",top:5,right:5,width:15}} />
          </div>

          <div className='w-3/4  my-auto  '>
            <p>
            Now press on the release button of the adapter to create a negative pressure in the syringe. This will cause the plunger of the syringe to move upwards.
            </p>
            <ul style={{listStyleType:"disc"}} className='ml-5'>
              <li>
              Note! If after pressing on the release button, the plunger does now move automatically, use your hand to gently pull on the plunger upwards to manually create the negative pressure in the syringe.
              </li>
            </ul>
          </div>

        </div>

        
        {/* Step 4 */}
        <div  className="flex mx-auto px-2 mb-5" style={{display:"flex"}} >

          <div className='mr-3'>
            <img src="./images/fna/step_4.png" alt="step 2" className="" style={{width: 100}} /> 
          </div>

          <div className='w-3/4  my-auto '>
            <p>
            Move the needle tip back and forth within the mass in a series of short, staccato strokes.
            </p>
          </div>

        </div>

        
        {/* Step 5 */}
        <div  className="flex mx-auto  px-2  mb-5" style={{display:"flex"}} >

          <div className='mr-3'style={{width: 100,height: 100,backgroundImage:"url('./images/fna/bg.png')",backgroundSize:"contain",position:"relative",backgroundRepeat:"no-repeat"}}>
            <img src="./images/fna/step_5_image.png" alt="step 1" className="mx-auto pt-2" style={{width: 50, }} /> 
            <img src="./images/fna/5.png" alt="" style={{position:"absolute",top:5,right:5,width:15}} />
          </div>

          <div className='w-3/4  my-auto '>
            <p>
            Remove the needle from the lesion, then quickly and gently expressed the aspirate onto the slides.
            </p>
            <ul style={{listStyleType:"disc"}} className='ml-5'>
              <li>
                Note that you can express the aspirate by first taking out the syringe from the adapter but remember to put the needle cap first before taking out the syringe.
              </li>
            </ul>
          </div>

        </div>


      </section>

{/* Step 3 */}

    </div>
  )
}

export default FnaAdapterPage