import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Navigation() {
  const [isOpen, setIsOpen] = useState(false);
  const handleNavLinkClick = () => {
    window.open('https://dashboard.gmscope.org/', '_blank');
  };
  return (
    // <nav className="bg-gray-800 flex items-center justify-between flex-wrap">

    //   <div className="container mx-auto flex flex-row items-center justify-between py-4 px-4 lg:px-0  ">
    //     <div className="flex items-center">
    //       <img
    //         src="./logo192.png"
    //         alt="Logo"
    //         className="App-logo h-8 w-auto mr-4"
    //       />
    //       <span className="text-white font-semibold text-lg">React Corner</span>
    //     </div>

    //     {/* Hamburger Action */}

    //     <div className="block lg:hidden ">
    //       <button
    //         onClick={() => setIsOpen(!isOpen)}
    //         className="flex items-center px-3 py-2 bg-amber-500 border-0 py-2 px-4 text-white rounded text-black-500 hover:text-black-400"
    //       >
    //         <svg
    //           className={`fill-current h-3 w-3 ${isOpen ? "hidden" : "block"}`}
    //           viewBox="0 0 20 20"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
    //         </svg>
    //         <svg
    //           className={`fill-current h-3 w-3 ${isOpen ? "block" : "hidden"}`}
    //           viewBox="0 0 20 20"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
    //         </svg>
    //       </button>
    //     </div>

    //     <div className={` lg:flex lg:items-center lg:w-auto ${isOpen ? "block" : "hidden"}`}>

    //     <div className="flex  items-center space-x-4">
    //       <NavLink
    //         to="/"
    //         className="text-gray-300 text-blue-600 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    //       >
    //         Home
    //       </NavLink>
    //       <NavLink
    //         to="/about"
    //         className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    //       >
    //         FNA
    //       </NavLink>
    //       <NavLink
    //         to="/platform"
    //         className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    //       >
    //         Platform
    //       </NavLink>
    //       <NavLink
    //         to="/contact"
    //         className="text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
    //       >
    //         Contact
    //       </NavLink>

    //     </div>

    //     </div>

    //   </div>
    // </nav>

    <div className="shadow-sm">

    <div className="container mx-auto ">
      <nav className="flex items-center justify-between flex-wrap lg:py-4 py-2 lg-px-0 px-2 ">
        <div className="flex items-center flex-shrink-0 text-white">
          <img
            src="./logo.png"
            alt="Logo"
            className="lg:h-10 h-7 w-auto mr-4"
          />
        </div>

        <div className="block lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="flex items-center px-3 py-2 rounded text-black-500 hover:text-black-400"
          >
            <svg
              className={`fill-current h-5 w-5 ${isOpen ? "hidden" : "block"}`}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
            <svg
              className={`fill-current h-5 w-5 ${isOpen ? "block" : "hidden"}`}
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>

        <div
          className={`w-full lg:text-end block flex-grow lg:flex lg:items-center lg:w-auto ${
            isOpen ? "block" : "hidden"
          }`}
        >
          <div className="text-sm lg:flex-grow block">
            <NavLink
              to="/"
              exact
              activeClassName="border-b-2 border-blue-500 rounded-0"
              className="block mr-4 text-end mt-3 lg:inline-block hover:text-gray-400 px-1  text-sm font-medium"
            >
              Home
            </NavLink>
            <NavLink
              to="/fna-adapter"
              activeClassName="border-b-2 border-blue-500 rounded-0"
              className="block mr-4 text-end mt-3 lg:inline-block hover:text-gray-400 px-1  text-sm font-medium"
            >
              FNA Adapter
            </NavLink>
            
            <NavLink
              to="#" 
              onClick={handleNavLinkClick}
              
              activeClassName=""
              className="block mr-4 text-end mt-3 lg:inline-block hover:text-gray-400 px-1  text-sm font-medium"
            >
              Platform
            </NavLink>
            <NavLink
              to="/contact"
              activeClassName="border-b-2 border-blue-500 rounded-0"
              className="block mr-4 text-end mt-3 lg:inline-block hover::text-gray-400 px-1  text-sm font-medium"
            >
              Contact
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
    </div>

  );
}

export default Navigation;
