import React from 'react'

function FNABanner() {
  return (
    <div className="shadow-lg" style={{backgroundColor:"", backgroundImage:"url(./banner.png)", backgroundSize:"cover", backgroundRepeat:"no-repeat", position:"relative"}}>
    {/* <img src="./banner.png" alt="Pathology" style={{height: 500}} />       */}

        <div id="fnaBanner" className="py-10">
            
                <div className='container flex flex-row mx-auto'>

                    <div className="h-full p-3">
                        <br />
                        <br />
                        <h1 className="text-2xl font-bold">FNA Syringe Adapter</h1>
                        <br />
                        <p className="lg:w-1/2">This device allows to perform cytology sampling through fine needle aspirate(FNA) biopsies and emptying the cystic lesions.
                        <span className='lg:inline-block hidden'>
                            It has a robust plastic
                            design which makes it very comfortable and efficient for sue during FNA procedures. Its unique design also enables single handed use. with an optimal
                            negative pressure provided for FNA Biopsy procedures. It equally has a wide domain of FNA applications.
                        </span> 
                        </p>
                    </div>

                    <div className='p-5 sm:w-1/2'>
                        <img src="./images/fna/fna.png" alt="FNA Needle" style={{height:300, width:100, maxWidth: 110}} />
                    </div> 
                    
                </div>

        </div>

  </div> 
  )
}

export default FNABanner