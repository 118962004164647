import React from 'react'

function Footer() {
  return (
    <div className="bg-gray-800 p-4">
      <div className="flex container mx-auto flex-row align-center text-white">
        <div style={{display:"flex",height:16,alignItems:"center"}}><img src="./images/phone.png" style={{height:23}} className='mr-1' alt="Phone Number" /> <p className='mr-5'>+237691896165</p></div>
        <div style={{display:"flex",height:16,alignItems:"center"}}><img src="./images/mail.png" style={{height:20}} className='mr-1' alt="Phone Number" /> <p>info@gictelemed.org</p></div>
      </div>

    </div>
  )
}

export default Footer