import React from 'react'

function Service({name,src}) {
  return (
    <div id="box" className="  px-8 py-3">
        <div style={{position:"relative"}} className="bg-white rounded-lg p-5 pb-10 shadow-lg">
            <img style={{height: 200, width:"100%"}} alt={name} className="rounded-lg mb-3" src={src}/>
            <h1 className="text-center">{name}</h1>
            
            <img src="./images/Vector.png" alt="forward arrow" className="bg-blue-600 py-3 px-4  rounded-full" style={{position:"absolute",left:"45%",bottom:-20}} />  

        </div>

    </div>
  )
}

export default Service      