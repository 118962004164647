import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage';
import FnaAdapterPage from './pages/FnaAdapterPage';
import Platform from './pages/Platform';
import Contact from './pages/Contact';
import Navigation from './components/Navigation';
import Footer from './components/Footer';


function App() {
  return (
    <Router>
      <Navigation />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/fna-adapter" component={FnaAdapterPage} />
        <Route path="/platform" component={Platform} />
        <Route path="/contact" component={Contact} />
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
