import React from 'react'

function Banner() {
  return (
    <>
    <div style={{height: 500,backgroundColor:"", backgroundImage:"url(./banner.png)", backgroundSize:"cover", backgroundRepeat:"no-repeat", position:"relative"}}>
      {/* <img src="./banner.png" alt="Pathology" style={{height: 500}} />       */}

    <div id="banner" style={{position:"absolute", bottom: 0, left:0,right:0}} className="py-10">
      <div className='lg:w-2/5 text-center mx-auto'>
        <p>GM Scope is changing the state of pathology and Microscopy in general, by bringing Telepathology and Tele-Microscropy to the most underserved areas.</p>
      </div>
      
      <br />

      <div  className='lg:w-2/5 text-center mx-auto'>
      <p>Convert your slides into Whole slide Images, and access these digital fiels anytime, anywhere, as well as easily collaborate with yout colleagues and peers.</p>
      </div>

    </div>

    </div> 

    </>

  )
}

export default Banner