import React from 'react'

function PropItem({src,title,value}) {
  return (
    <div className="px-3">
        <div style={{boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}} className='bg-white pb-5 rounded-lg p-5 '>
            <img src={src} style={{width:70}} alt={title} className="bg-blue-100 rounded-md p-3 mb-4"/>
            <h1 className="font-bold">{title}</h1>
            <p className="text-sm">{value}</p>
        </div>
    </div>

  )
}

export default PropItem