import React from 'react'
import Banner from '../components/Banner'
import Service from '../components/Service'

function HomePage() {
  const services = [
    {
      name:"Specimen Prep",
      src:"./images/Pic_2.png"
    },
    {
      name:"Slide Scanning",
      src:"./images/Pic_3.png"
    },
    {
      name:"Collaborate in real time",
      src:"./images/Pic_4.png"
    },
    {
      name:"Second Opinions",
      src:"./images/Pic_5.png"
    },
    {
      name:"Pathology COnsulations",
      src:"./images/Pic_6.png"
    },
    {
      name:"WSI Storage",
      src:"./images/Pic_7.png"
    },
    {
      name:"Personalized Electronic Health Records",
      src:"./images/Pic_8.png"
    },

  ]
  return (
    <div className="bg-gray-100 pb-5">
      <Banner />
      <div className='container mx-auto'>
        <h2 className='text-center text-lg mt-5 p-5'>Services</h2>
        
        <div className='card-grid'>
          
          {
            services.map(service => (
              <Service key={service.name} name={service.name} src={service.src} />  
            ))
          }
                
        </div>
      </div>
    </div>
  )
}

export default HomePage